import { GET_SYSTEMS, GET_SYSTEMS_SUCCESS, GET_SYSTEMS_FAIL, GET_SYSTEM, ADD_SYSTEM, ADD_SYSTEM_FAIL, ADD_SYSTEM_SUCCESS, UPDATE_SYSTEM, UPDATE_SYSTEM_FAIL, UPDATE_SYSTEM_SUCCESS, DELETE_SYSTEM, DELETE_SYSTEM_FAIL, DELETE_SYSTEM_SUCCESS } from '../types';

const initialState = {
  loading: false,
  fail: false,
  success: false,
  systems: {},
  system: {}
};

export default function(state = initialState, action) {
 switch (action.type) {
  case GET_SYSTEMS:
    return { ...state, loading: true, fail: false, success: false  };
  case GET_SYSTEMS_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      systems: action.payload
    };
  case GET_SYSTEMS_FAIL:
    return {...state,  loading: false, fail: true };
  case ADD_SYSTEM:
    return { ...state, loading: true, fail: false, success: false  };
  case ADD_SYSTEM_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      success: true,
      system: action.payload
    };
  case ADD_SYSTEM_FAIL:
    return {...state,  loading: false, fail: true, success: false };
  case GET_SYSTEM:
    return {
      ...state,
      loading: false,
      system: action.payload
    };
  case UPDATE_SYSTEM:
    return { ...state, loading: true, fail: false, success: false };
  case UPDATE_SYSTEM_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      success: true,
      system: action.payload
    };
  case UPDATE_SYSTEM_FAIL:
    return {...state,  loading: false, fail: true, success: false };
  case DELETE_SYSTEM:
    return { ...state, loading: true, fail: false, success: false };
  case DELETE_SYSTEM_SUCCESS:
    const newSystems = state.systems.filter(system => system.s_id !== action.payload)
    return {
      ...state,
      systems: newSystems,
      loading: false,
      fail: false,
      success: true
    };
  case DELETE_SYSTEM_FAIL:
    return {...state,  loading: false, fail: true, success: false, message: action.payload };
    default:
     return state;
  }
}
