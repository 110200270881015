import { SET_CURRENT_USER, LOGIN_USER_FAIL, LOGIN_USER, REGISTER_USER, REGISTER_USER_FAIL, REGISTER_USER_SUCCESS } from '../types';

const initialState = {
 loading: false,
 fail: false,
 success: false,
 message: '',
 isAuthenticated: false,
 user: {}
};

export default function(state = initialState, action) {
 switch (action.type) {
  case LOGIN_USER:
    return { ...state, loading: true };
  case LOGIN_USER_FAIL:
    return {...state,  loading: false, fail: true };
  case SET_CURRENT_USER:
    return {
      ...state,
      loading: false,
      isAuthenticated: true,
      userRole: action.userRole,
      user: action.payload
    };
  case REGISTER_USER:
    return { ...state, loading: true };
  case REGISTER_USER_FAIL:
    return {...state,  loading: false, fail: true, success: false, message: action.payload  };
  case REGISTER_USER_SUCCESS:
    return {...state,  loading: false, fail: false, success: true };
  default:
   return state;
  }
}
