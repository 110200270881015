import { GET_BONS, GET_BONS_SUCCESS, GET_BONS_FAIL, GET_BON, GET_BON_NOTIFICATION, GET_BON_NOTIFICATION_SUCCESS, GET_BON_NOTIFICATION_FAIL } from '../types';

const initialState = {
  loading: false,
  loadingNotification: false,
  fail: false,
  success: false,
  bons: {},
  bon: {}
};

export default function(state = initialState, action) {
 switch (action.type) {
  case GET_BONS:
    return { ...state, loading: true, fail: false, success: false  };
  case GET_BONS_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      bons: action.payload.data,
      total: action.payload.total
    };
  case GET_BONS_FAIL:
    return {...state,  loading: false, fail: true, message: action.payload };
  case GET_BON_NOTIFICATION:
    return {...state,  loadingNotification: true };
  case GET_BON_NOTIFICATION_SUCCESS:
    return {...state,  loadingNotification: false, success: true, message: action.payload };
  case GET_BON_NOTIFICATION_FAIL:
    return {...state,  loadingNotification: false, fail: true, message: action.payload };
  case GET_BON:
    return {
      ...state,
      loading: false,
      bon: action.payload
    };
  default:
     return state;
  }
}
