import { combineReducers } from 'redux';
import AuthReducer from './Login/AuthReducer';
import CompanyReducer from './Company/CompanyReducer';
import PartnerReducer from './Partner/PartnerReducer';
import SystemReducer from './System/SystemReducer';
import BonReducer from './Bon/BonReducer';
import UserReducer from './User/UserReducer';

export default combineReducers({
   auth: AuthReducer,
   company: CompanyReducer,
   partner: PartnerReducer,
   system: SystemReducer,
   bon: BonReducer,
   user: UserReducer
});
