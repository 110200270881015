import { GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL, GET_USERS_SUCCESS_CONFİRMED, GET_USERS_FAIL_CONFİRMED } from '../types';

const initialState = {
  loading: false,
  fail: false,
  success: false,
  users: {},
};

export default function(state = initialState, action) {
 switch (action.type) {
  case GET_USERS:
    return { ...state, loading: true, fail: false, success: false  };
  case GET_USERS_SUCCESS:
    return {
      ...state,
      loading: false,
      users: action.payload
    };
  case GET_USERS_FAIL:
    return {...state,  loading: false, message: action.payload };
  case GET_USERS_SUCCESS_CONFİRMED:
    return {
      ...state,
      loading: false,
      fail: false,
      success: true,
      users: action.payload
    };
  case GET_USERS_FAIL_CONFİRMED:
    return {...state,  loading: false, fail: true, success: false, message: action.payload };
  default:
     return state;
  }
}
