import { GET_COMPANIES, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAIL, GET_COMPANY, ADD_COMPANY, ADD_COMPANY_FAIL, ADD_COMPANY_SUCCESS, UPDATE_COMPANY, UPDATE_COMPANY_FAIL, UPDATE_COMPANY_SUCCESS, DELETE_COMPANY, DELETE_COMPANY_FAIL, DELETE_COMPANY_SUCCESS } from '../types';

const initialState = {
  loading: false,
  fail: false,
  success: false,
  companies: {},
  company: {}
};

export default function(state = initialState, action) {
 switch (action.type) {
  case GET_COMPANIES:
    return { ...state, loading: true, fail: false, success: false  };
  case GET_COMPANIES_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      companies: action.payload
    };
  case GET_COMPANIES_FAIL:
    return {...state,  loading: false, fail: true };
  case ADD_COMPANY:
    return { ...state, loading: true, fail: false, success: false  };
  case ADD_COMPANY_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      success: true,
      company: action.payload
    };
  case ADD_COMPANY_FAIL:
    return {...state,  loading: false, fail: true, success: false };
  case GET_COMPANY:
    return {
      ...state,
      loading: false,
      company: action.payload
    };
  case UPDATE_COMPANY:
    return { ...state, loading: true, fail: false, success: false };
  case UPDATE_COMPANY_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      success: true,
      company: action.payload
    };
  case UPDATE_COMPANY_FAIL:
    return {...state,  loading: false, fail: true, success: false };
  case DELETE_COMPANY:
    return { ...state, loading: true, fail: false, success: false };
  case DELETE_COMPANY_SUCCESS:
    const newCompanies = state.companies.filter(company => company.id !== action.payload)
    return {
      ...state,
      companies: newCompanies,
      loading: false,
      fail: false,
      success: true
    };
  case DELETE_COMPANY_FAIL:
    return {...state,  loading: false, fail: true, success: false, message: action.payload };
    default:
     return state;
  }
}
