import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './services/Login/SetAuthToken';

//Style File
import './App.scss';

//set token from cookie
setAuthToken();


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login'));
const Register = React.lazy(() => import('./views/Register'));
// import Login from './views/Login';

class App extends Component {
  render() {
    return (
     <Provider store={store}>
       <HashRouter>
           <React.Suspense fallback={loading()}>
             <Switch>
               <Route exact path="/register" name="Register" render={props => <Register {...props}/>} />
               <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
               <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
               <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
             </Switch>
           </React.Suspense>
       </HashRouter>
     </Provider>
    );
  }
}

export default App;
