export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';
export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const GET_COMPANY = 'GET_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';

export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAIL = 'GET_PARTNERS_FAIL';
export const ADD_PARTNER = 'ADD_PARTNER';
export const ADD_PARTNER_FAIL = 'ADD_PARTNER_FAIL';
export const ADD_PARTNER_SUCCESS = 'ADD_PARTNER_SUCCESS';
export const GET_PARTNER = 'GET_PARTNER';
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_PARTNER_FAIL = 'UPDATE_PARTNER_FAIL';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const DELETE_PARTNER_FAIL = 'DELETE_PARTNER_FAIL';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';

export const GET_SYSTEMS = 'GET_SYSTEMS';
export const GET_SYSTEMS_SUCCESS = 'GET_SYSTEMS_SUCCESS';
export const GET_SYSTEMS_FAIL = 'GET_SYSTEMS_FAIL';
export const ADD_SYSTEM = 'ADD_SYSTEM';
export const ADD_SYSTEM_FAIL = 'ADD_SYSTEM_FAIL';
export const ADD_SYSTEM_SUCCESS = 'ADD_SYSTEM_SUCCESS';
export const GET_SYSTEM = 'GET_SYSTEM';
export const UPDATE_SYSTEM = 'UPDATE_SYSTEM';
export const UPDATE_SYSTEM_FAIL = 'UPDATE_SYSTEM_FAIL';
export const UPDATE_SYSTEM_SUCCESS = 'UPDATE_SYSTEM_SUCCESS';
export const DELETE_SYSTEM = 'DELETE_SYSTEM';
export const DELETE_SYSTEM_FAIL = 'DELETE_SYSTEM_FAIL';
export const DELETE_SYSTEM_SUCCESS = 'DELETE_SYSTEM_SUCCESS';

export const GET_BONS = 'GET_BONS';
export const GET_BONS_SUCCESS = 'GET_BONS_SUCCESS';
export const GET_BONS_FAIL = 'GET_BONS_FAIL';
export const GET_BON = 'GET_BON';
export const GET_BON_NOTIFICATION = 'GET_BON_NOTIFICATION';
export const GET_BON_NOTIFICATION_SUCCESS = 'GET_BON_NOTIFICATION_SUCCESS';
export const GET_BON_NOTIFICATION_FAIL = 'GET_BON_NOTIFICATION_FAIL';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_USERS_SUCCESS_CONFİRMED = 'GET_USERS_SUCCESS_CONFİRMED';
export const GET_USERS_FAIL_CONFİRMED = 'GET_USERS_FAIL_CONFİRMED';
