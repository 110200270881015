import { GET_PARTNERS, GET_PARTNERS_SUCCESS, GET_PARTNERS_FAIL, GET_PARTNER, ADD_PARTNER, ADD_PARTNER_FAIL, ADD_PARTNER_SUCCESS, UPDATE_PARTNER, UPDATE_PARTNER_FAIL, UPDATE_PARTNER_SUCCESS, DELETE_PARTNER, DELETE_PARTNER_FAIL, DELETE_PARTNER_SUCCESS } from '../types';

const initialState = {
  loading: false,
  fail: false,
  success: false,
  partners: {},
  partner: {}
};

export default function(state = initialState, action) {
 switch (action.type) {
  case GET_PARTNERS:
    return { ...state, loading: true, fail: false, success: false  };
  case GET_PARTNERS_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      partners: action.payload
    };
  case GET_PARTNERS_FAIL:
    return {...state,  loading: false, fail: true, message: action.payload };
  case ADD_PARTNER:
    return { ...state, loading: true, fail: false, success: false  };
  case ADD_PARTNER_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      success: true,
      partner: action.payload
    };
  case ADD_PARTNER_FAIL:
    return {...state,  loading: false, fail: true, success: false };
  case GET_PARTNER:
    return {
      ...state,
      loading: false,
      partner: action.payload
    };
  case UPDATE_PARTNER:
    return { ...state, loading: true, fail: false, success: false };
  case UPDATE_PARTNER_SUCCESS:
    return {
      ...state,
      loading: false,
      fail: false,
      success: true,
      partner: action.payload
    };
  case UPDATE_PARTNER_FAIL:
    return {...state,  loading: false, fail: true, success: false };
  case DELETE_PARTNER:
    return { ...state, loading: true, fail: false, success: false };
  case DELETE_PARTNER_SUCCESS:
    const newPartners = state.partners.filter(partner => partner.pr_id !== action.payload)
    return {
      ...state,
      partners: newPartners,
      loading: false,
      fail: false,
      success: true
    };
  case DELETE_PARTNER_FAIL:
    return {...state,  loading: false, fail: true, success: false };
    default:
     return state;
  }
}
